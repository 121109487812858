import React from 'react';

function HashoutGuideToConnectingAemAndCrmForSelfService() {
    return (
        <div style={{height: "100vh"}}>
            <object data="../../Hashout-Guide-to-connecting-AEM-and-CRM-for-self-service.pdf#toolbar=0" type="application/pdf" width="100%" height="100%">
                <a href="../../Hashout-Guide-to-connecting-AEM-and-CRM-for-self-service.pdf"></a>
            </object>
        </div>
    )
}

export default HashoutGuideToConnectingAemAndCrmForSelfService
